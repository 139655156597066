import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" >


<path d="M6115 9339 c-104 -61 -208 -121 -230 -133 -22 -13 -78 -46 -125 -73
-47 -28 -218 -127 -380 -220 -162 -93 -356 -205 -430 -248 -163 -95 -582 -337
-737 -426 l-112 -64 -1 -1282 0 -1281 136 -79 c76 -43 155 -89 178 -102 23
-12 93 -53 156 -90 63 -37 212 -123 330 -191 118 -68 264 -152 323 -187 60
-35 113 -63 118 -63 5 0 18 -8 29 -17 11 -10 138 -85 282 -168 145 -83 351
-202 458 -264 l195 -113 55 29 c30 16 101 56 157 89 l103 59 0 269 c0 226 -2
267 -14 263 -8 -3 -69 -37 -135 -76 -146 -85 -160 -89 -203 -63 -18 11 -53 31
-78 45 -51 28 -414 237 -655 377 -244 142 -517 299 -752 434 l-213 121 0 1007
0 1006 78 45 c42 25 145 84 227 133 83 48 179 104 215 124 60 34 506 290 570
328 16 9 75 44 132 76 57 33 195 113 307 178 177 103 208 118 227 109 12 -5
78 -42 146 -82 68 -39 143 -83 168 -97 25 -13 70 -39 100 -57 30 -18 190 -111
355 -205 165 -95 312 -181 327 -191 15 -10 29 -19 32 -19 2 0 84 -47 182 -104
99 -56 195 -112 214 -123 19 -11 72 -41 117 -67 l83 -48 2 -173 3 -174 140 81
c77 44 182 105 233 135 l92 54 0 177 -1 177 -102 59 c-219 126 -638 367 -757
436 -151 87 -367 212 -675 390 -345 199 -470 271 -577 333 -54 32 -99 57 -100
56 -2 0 -88 -50 -193 -110z"/>
<path d="M5510 6895 l0 -1055 125 0 125 0 2 200 3 200 115 -152 c63 -83 120
-156 125 -162 6 -6 23 -28 38 -48 l28 -38 155 0 c84 0 154 3 154 6 0 7 -9 19
-233 302 l-168 213 48 67 c26 37 99 137 162 222 63 85 123 169 135 185 21 30
21 36 18 573 l-2 542 -130 0 -130 0 -2 -267 -3 -268 -183 268 -184 267 -99 0
-99 0 0 -1055z m387 320 c66 -93 145 -207 176 -252 l58 -83 -40 0 c-42 0 -48
-7 -167 -177 -92 -132 -148 -209 -155 -218 -5 -5 -9 190 -9 449 0 279 4 456 9
454 5 -1 63 -79 128 -173z"/>
<path d="M8124 5917 c-109 -63 -490 -283 -679 -392 -55 -32 -117 -69 -137 -81
-21 -13 -40 -24 -42 -24 -3 0 -29 -14 -58 -31 -29 -18 -91 -54 -138 -81 l-84
-49 -51 27 c-27 15 -70 40 -95 55 l-45 28 -3 -376 c-1 -207 0 -378 2 -380 2
-2 73 36 157 85 85 48 246 141 359 206 113 65 225 131 250 146 25 15 65 39 90
53 63 35 552 316 923 531 64 38 117 71 117 75 0 3 -33 24 -73 47 -90 51 -312
180 -353 205 l-30 18 -110 -62z"/>
<path d="M8234 3571 c-5 -6 -141 -8 -764 -15 -294 -3 -677 -11 -850 -17 -484
-17 -572 -19 -615 -17 -22 1 -56 1 -75 1 -19 -1 -165 -4 -325 -7 -159 -4 -343
-11 -407 -16 -64 -6 -181 -10 -260 -10 -198 0 -649 -28 -673 -42 -11 -7 -38
-15 -60 -18 -116 -18 606 -30 1785 -30 437 0 806 0 820 0 14 0 207 -2 430 -6
223 -3 538 -7 700 -9 370 -4 353 -3 361 -16 5 -8 13 -8 29 1 14 7 40 9 73 5
111 -16 207 0 207 35 0 9 10 24 21 32 18 12 20 17 10 35 -6 12 -8 29 -4 39 4
11 2 15 -4 11 -6 -4 -30 -1 -54 7 -110 33 -189 47 -263 44 -43 -2 -79 -5 -82
-7z"/>
<path d="M8265 3360 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
